<template>
  <div class="row">
    <div class="col-12">
    <!-- 위험등록부 목록 -->
      <c-table
        ref="tableRisk"
        title="위험등록부 목록"
        tableId="riskRegister01"
        :columns="gridRisk.columns"
        :data="riskReduce.riskbooks"
        :merge="gridRisk.merge"
        gridHeightAuto
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip
              v-for="(item, index) in $comm.ibmTagItems(props.row)"
              :key="index"
              outline square
              :color="item.color"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              />
            </q-chip>
          </template>
          <template v-else>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-12">
      <!-- 개선 목록 -->
      <c-table
        ref="tableImpr"
        title="개선 목록"
        tableId="impr01"
        :columns="gridImpr.columns"
        :data="riskReduce.imprs"
        :merge="gridImpr.merge"
        gridHeightAuto
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        @linkClick="linkClick"
      >
        <!-- :highLightInfo="gridImpr.highLightInfo" -->
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'charm-register-TL',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramStepCd: '',
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      riskReduce: {
        imprs: [],
        riskbooks: [],
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: '공정',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: '화학자재',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전보건조치
            label: '현재안전보건조치',
            align: 'left',
            style: 'width:270px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeExposureLevelFinal',
                field: 'beforeExposureLevelFinal',
                // 노출수준
                label: '노출수준',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'beforeToxic',
                field: 'beforeToxic',
                // 유해성
                label: '유해성',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'beforeRiskEstimation',
                field: 'beforeRiskEstimation',
                // 위험도
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                sortable: false,
              },
            ]
          },
          {
            name: 'addingRiskManagementActivies',
            field: 'addingRiskManagementActivies',
            // 감소대책
            label: '감소대책',
            align: 'left',
            style: 'width:270px',
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterExposureLevelFinal',
                field: 'afterExposureLevelFinal',
                // 노출수준
                label: '노출수준',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'afterToxic',
                field: 'afterToxic',
                // 유해성
                label: '유해성',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'afterRiskEstimation',
                field: 'afterRiskEstimation',
                // 위험도
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                type: 'proxy'||'',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: '평가자',
            align: 'center',
            userId: 'assessUserId',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:180px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        height: '500px'
      },
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
        ],
        columns: [],
        data: [],
        highLightInfo: {
          val: 'scenarioId',
          ids: [],
        },
        height: '500px'
      },
      colorItems: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 20) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.charm.riskReduce.list.url;
      // code setting
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.gridImpr.columns = [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: '공정',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: '화학자재',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전보건조치
            label: '현재안전보건조치',
            align: 'left',
            style: 'width:270px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivies',
            field: 'addingRiskManagementActivies',
            // 감소대책
            label: '감소대책',
            align: 'left',
            style: 'width:270px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:270px',
            sortable: true,
          },
          {
            name: 'ibmStepCd',
            field: 'ibmStepCd',
            // 진행상태
            label: '진행상태',
            align: 'center',
            style: 'width:80px',
            type: 'tag',
            colorItems: _result,
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            // 요청부서정보
            label: '요청부서정보',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료요청일
            label: '조치완료요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
        this.colorItems = _result
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openImpr(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup2;
    },
    closeImprPopup2(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableRisk'].$refs['compo-table'].resetVirtualScroll();
      }
    },
  }
};
</script>